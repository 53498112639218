.__react-dialog__ {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow-y: scroll;
  opacity: 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}
.__react-dialog__ .dialog {
  width: 270px;
  margin-left: -135px;
  text-align: center;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  border-radius: 13px;
  color: #000;
  position: absolute;
  left: 50%;
  margin-top: -70px;
  top: 50%;
}
.__react-dialog__ .dialog-inner {
  padding: 15px;
  border-radius: 13px 13px 0 0;
  background: rgba(255, 255, 255, 0.95);
  position: relative;
  text-align: center;
  color: #000;
  border-bottom: 1px solid #cccccc;
}
.__react-dialog__ .dialog-buttons {
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.__react-dialog__ .dialog-button:first-child {
  border-radius: 0 0 0 13px;
}
.__react-dialog__ .dialog-button {
  text-decoration: none;
  width: 100%;
  padding: 0 5px;
  height: 44px;
  font-size: 17px;
  line-height: 44px;
  text-align: center;
  color: #007aff;
  display: block;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  background: rgba(255, 255, 255, 0.95);
}
.__react-dialog__ .dialog-button.dialog-button-bold {
  font-weight: 500;
}
.__react-dialog__ .dialog-button:last-child {
  border-radius: 0 0 13px 0;
  border-left: 1px solid #cccccc;
}
.__react-dialog__ .dialog-title {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}
.__react-dialog__ .dialog-title + .dialog-text {
  margin-top: 5px;
}
.__react-dialog__ .dialog-title {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 10px;
}
.__react-dialog__ .dialog-button:first-child:last-child {
  border-radius: 0 0 13px 13px;
}
.__react-dialog-wrapper__.in .__react-dialog__ {
  opacity: 1;
}


#error-trial .__react-dialog__ .dialog{
  width: 320px;
  margin-left: -160px;
  margin-top: -170px;
}